<template>
  <v-card flat>
    <v-dialog v-model="showDialog">
      <MenuEditDialog
        :item="editItem"
        :categories="categories"
        :tags="tags"
        v-on:close="showDialog=false"
        v-on:save="saveItem"
        v-on:delete="deleteItem"
        v-on:add-tag="addTag"
      />
    </v-dialog>

    <v-card-title>
      Edit Menu
      <v-spacer/>
      <v-btn outlined @click="editRow({})">New</v-btn>
    </v-card-title>
    <v-card-actions>
      <v-text-field
        clearable
        v-model="search"
        label="Search"
        prepend-inner-icon="mdi-magnify"
      />
    </v-card-actions>
    <v-card-text>
      <v-data-table
        :search="search"
        :items="menu"
        :headers="headers"
        hide-default-footer
        disable-pagination
        @click:row="editRow"
      >
        <template v-slot:item.tags="{item : { tags }}">
          <v-chip
            v-for="(tag,index) of tags"
            v-bind:key="index"
            outlined
            x-small>
            {{ tag }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import api from "@/api";
import MenuEditDialog from "@/components/MenuEditDialog";

export default {
  name: "MenuEdit",
  components: {MenuEditDialog},
  data() {
    return {
      menu: [],
      categories: [],
      tags: [],
      showDialog: false,
      editItem: undefined,
      editMeal: null,
      search: null,
      headers: [
        {"value": "code", "text": "code"},
        {"value": "id", "text": "id"},
        {"value": "name", "text": "name"},
        {"value": "price", "text": "price"},
        {"value": "category", "text": "category"},
        {"value": "index", "text": "index"},
        {"value": "tags", "text": "tags"},
        {"value" : "availableFrom", text: "from"},
        {"value" : "availableTo", text: "to"},
      ]
    }
  },
  mounted() {
    return this.fetchMenu();
  },
  methods: {
    fetchMenu() {
      api.get('v2/menu/static')
        .then(({data}) => {
          this.updateMenu(data);
        })
    },
    updateMenu(data) {
      console.log('got menu', data);
      const menu = [];
      const categories = Object.keys(data);
      categories.forEach(category => {
        const meals = data[category];
        meals.forEach(meal => {
          menu.push({
            tags: [],
            ...meal,
            category,
          });
        });
      });
      this.menu = menu;
      this.categories = categories;
      this.tags = [...new Set(this.menu.flatMap(item => item.tags || []))];
    },
    editRow(item) {
      this.$nextTick(() => {
        this.editItem = {...item};
        this.editMeal = {
          name: item.name,
          id: Number(item.id)
        }
        this.showDialog = true;
      });
    },
    saveItem(item) {
      console.log('saving', item);
      const payload = {
        ...item
      };
      return api.put('v2/menu/static', payload)
        .then(r => {
          console.log('success', r);
          this.updateMenu(r.data);
        })
        .catch(e => console.error('fail', e))
    },
    deleteItem(item) {
      console.log('deleting', item);
      const payload = {
        ...item
      };
      return api.put('v2/menu/static', payload, {params: {deleteItem: true}})
        .then(r => {
          console.log('success', r);
          this.updateMenu(r.data);
        })
        .catch(e => console.error('fail', e))
    },
    addTag(tag) {
      this.tags.push(tag);
    }
  },
  computed: {
    // headers() {
    //   const fields = [...new Set(this.menu.flatMap(item => Object.keys(item)))];
    //   return fields.map(f => ({value: f, text: f}));
    // }
  }
}
</script>

<style scoped>

</style>