import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":""}},[_c(VDialog,{model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('MenuEditDialog',{attrs:{"item":_vm.editItem,"categories":_vm.categories,"tags":_vm.tags},on:{"close":function($event){_vm.showDialog=false},"save":_vm.saveItem,"delete":_vm.deleteItem,"add-tag":_vm.addTag}})],1),_c(VCardTitle,[_vm._v(" Edit Menu "),_c(VSpacer),_c(VBtn,{attrs:{"outlined":""},on:{"click":function($event){return _vm.editRow({})}}},[_vm._v("New")])],1),_c(VCardActions,[_c(VTextField,{attrs:{"clearable":"","label":"Search","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VCardText,[_c(VDataTable,{attrs:{"search":_vm.search,"items":_vm.menu,"headers":_vm.headers,"hide-default-footer":"","disable-pagination":""},on:{"click:row":_vm.editRow},scopedSlots:_vm._u([{key:"item.tags",fn:function(ref){
var tags = ref.item.tags;
return _vm._l((tags),function(tag,index){return _c(VChip,{key:index,attrs:{"outlined":"","x-small":""}},[_vm._v(" "+_vm._s(tag)+" ")])})}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }